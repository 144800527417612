<template>
  <layout-banner>
    <b-row class="px-2 pb-5">
      <b-col cols="12" class="pb-5">
        <Stepper :steps="steps" :flowStep="flowStep" :activeStep="activeStep" @setStep="setStep" />
        <b-row>
          <b-col cols="12">
            <FormValidarDatos v-if="activeStep == 0" @nextStep="nextStep" />
            <FormActualizarDatos v-if="activeStep == 1" @nextStep="nextStep" />
            <FormConfirmarIdentidad v-if="activeStep == 2" @nextStep="nextStep" :isActualizarDatos="true" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </layout-banner>
</template>

<script>
import mixinsEvents from "@/app/shared/mixins/mixins-events";

// COMPONENTS
import Stepper from "@/app/shared/components/Stepper.vue";
import LayoutBanner from "@/app/shared/layouts/Banner.vue";
import FormValidarDatos from "@/app/shared/components/FormValidarDatos.vue";
import FormActualizarDatos from "@/app/shared/components/FormActualizarDatos.vue";
import FormConfirmarIdentidad from "@/app/shared/components/FormConfirmarIdentidad.vue";

export default {
  mixins: [mixinsEvents],
  components: { Stepper, LayoutBanner, FormValidarDatos, FormActualizarDatos, FormConfirmarIdentidad },
  data() {
    return {
      flowStep: 0,
      activeStep: 0,
      steps: [
        { name: "Confirma tu identidad" },
        { name: "Actualiza tus datos personales" },
        { name: "Valida tus nuevos datos" },
      ],
    };
  },
};
</script>
